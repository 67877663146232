<template>
	<div class="notifications">
		<div class="header" style="display:block">
			<h1>アカウント</h1>
		</div>
		<div class="body">
			<b-form inline>
				<div class="form-group">
				<div class="admin-block text-center">
					
					<b-row align-v="center">
						<b-col cols="4" class="text-right">
							<h2>メールアドレス</h2>
						</b-col>
						<b-col cols="6" >
							<b-form-input
							id="mail-address"
							placeholder=""
							v-model="form.mail_address"
							disabled
							></b-form-input>
						</b-col>
					</b-row>
					<b-row align-v="center">
						<b-col cols="4" class="text-right">
							<h2>グループキー</h2>
						</b-col>
						<b-col cols="6" >
							<b-form-input
							id="mail-address"
							placeholder=""
							v-model="form.group_key"
							disabled
							></b-form-input>
						</b-col>
					</b-row>
					<b-row align-v="center">
						<b-col cols="4" class="text-right">
							<h2>パスワード</h2>
						</b-col>
						<b-col cols="6" >
							<b-form-input
							disabled
							id="password"
							type="password"
							placeholder=""
							v-model="form.password"
							></b-form-input>
						</b-col>
					</b-row>
				</div>

				<div class="last-block">
					<div>
						<b-button variant="secondary" @click="$router.push('/admin')" >戻る</b-button>
						<b-button variant="primary" @click="onClickSave" disabled >更新</b-button>
					</div>
					
				</div>
				
			</div>
			</b-form>
		</div>
		
		
		
	</div>
</template>

<script>


export default {


	components: {
	},
	computed: {
		
	},
	data() {
		return {
			form: {
				mail_address: this.$store.state.api_email,
				group_key: this.$store.state.api_groupkey,
				password: this.$store.state.api_password
			}
		};
	},
	methods: {
		
		onClickSave(){
			console.log(this.form)
		}

	}
};
</script>
<style lang="scss">

</style>